import { Component, HostListener, OnInit } from '@angular/core';
import { SEOService } from '../services/SEO.service';
import { ActivatedRoute } from '@angular/router';
import { LocalizationService } from '../services/localization.service';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.css']
})
export class AboutMeComponent implements OnInit {
  private url: string = 'https://kasperwieliczko.com/about';
  public title: string = 'About me';
  public description: string = 'About Kasper Wieliczko'
  public footerPosition: string = "fixed"

  constructor(activeRoute: ActivatedRoute, localizationService: LocalizationService, private SEOService: SEOService) {
    let currentLang = activeRoute.snapshot.params["lang"];
    if (currentLang && currentLang !== localizationService.currentLang) {
      localizationService.switchLang(currentLang);
    }
    if (!currentLang) {
      localizationService.setUpLangUrl();
    }
  }

  ngOnInit() {
    this.SEO();
    if (window.innerWidth <= 992 && window.innerHeight < 430) {
      this.footerPosition = "relative"
    }
    else {
      this.footerPosition = "fixed"
    }
  }

  SEO() {
    this.SEOService.setTitle(this.title);
    this.SEOService.updateMetaTag({ name: 'description', content: this.description });
    this.SEOService.createLinkForCanonicalURL(this.url);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth <= 992 && window.innerHeight < 430) {
      this.footerPosition = "relative"
    }
    else {
      this.footerPosition = "fixed"
    }
  }
}
