import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizationService } from '../../services/localization.service';
import { PhotoService } from '../../services/photo.service';

@Component({
  selector: 'app-photo-magnify',
  templateUrl: './photo-magnify.component.html',
  styleUrls: ['./photo-magnify.component.css']
})
export class PhotoMagnifyComponent implements OnInit {

  public footerPosition = 'fixed';

  constructor(private photoService: PhotoService, public localizationService: LocalizationService, private router: Router, activeRoute: ActivatedRoute,) { 
    let currentLang = activeRoute.snapshot.params["lang"];
    if (currentLang && currentLang !== localizationService.currentLang) {
      localizationService.switchLang(currentLang);
    }
    if (!currentLang) {
      localizationService.setUpLangUrl();
    }
  }

  ngOnInit(): void {
  }

}
